<template>
  <!-- VIEW ICONS DEVICES -->
  <div>
    <L-Marker-Cluster
      :options="clusterOptions"
    >
      <div
        v-for="station in stations"
        :key="station.id"
      >
        <l-marker
          :lat-lng="getLatLongProj(station.coordinates[0], station.coordinates[1])"
          @click="clickIcon(station.id, station.name,station.max_apparent_power_kva)"
          @contextmenu="openContextMenu"
        >
          <StationIcon />
          <div v-if="station.flex_configuration === 'ready'">
            <StationIcon
              :name="station.name"
            />
          </div>
          <div v-else>
            <StationIconForecast
              :uuid="station.id"
              :name="station.name"
              :forecast-status="station.forecasted_status"
              :status="station.current_status"
              :power-values="[station.max_apparent_power_kva,station.min_apparent_power_kva,'-']"
              :status-name="getNameStatus(station.current_status)"
              :status-color="getColorStatus(station.current_status)"
              :status-name-forecast="getNameStatus(station.forecasted_status)"
              :status-color-forecast="getColorStatus(station.forecasted_status)"
            />
          </div>
        </l-marker>
      </div>
    </L-Marker-Cluster>
  </div>
</template>

<script>
import proj4 from 'proj4'
import StationIcon from '@/components/TIA/TIAMap/TIAStationIcon.vue'
import StationIconForecast from '@/components/TIA/TIAMap/TIAStationIconForecastRealTime.vue'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { LMarker } from 'vue2-leaflet'
import calculateStatus from '@/mixins/TIA/calculateStatus'

export default {
  name: 'TIAStation',

  components: {
    StationIcon,
    StationIconForecast,
    'L-Marker-Cluster': Vue2LeafletMarkerCluster,
    LMarker
  },
  mixins: [calculateStatus],
  props: {
    stations: {
      type: Array,
      default: () => ([])
    },
    zoom: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    clusterOptions: {
      showCoverageOnHover: false,
      chunkedLoading: true,
      disableClusteringAtZoom: 16,
      animateAddingMarkers: false,
      singleMarkerMode: false
    }
  }),

  mounted () {    
  },
  methods: {
    getNameStatus (status) {
      let statusName
      if (status === 'not_required_flex') {
        statusName = 'Non Active Flex'
      } else if (status === 'required_flex') {
        statusName = 'Active Flex'
      } else if (status === 'limit_override') {
        statusName = 'Outside limits'
      } else if (status === '') {
        statusName = 'No data'
      }
      return statusName
    },
    getColorStatus (status) {
      let colorName
      if (status === 'not_required_flex') {
        colorName = 'color:#34AE60'
      } else if (status === 'required_flex') {
        colorName = 'color:#dde675'
      } else if (status === 'limit_override') {
        colorName = 'color:#EC7063'
      } else if (status === '') {
        colorName = 'color:rgba(57, 81, 97, 0.9)'
      }
      return colorName
    },
    getLatLongProj (lat, long) {
      // HERE YOU HAVE TO PASS X,Y IN THE 3 PARAMETER, AND THEN CHANGE THE ORDER
      // const loc = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [long, lat])
      const location = [lat,long]
      return location
    },
    clickIcon (id, name,maxPower) {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.stationSelected', value: id })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.maxPowerStation', value: maxPower })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.nameStationSelected', value: name })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 1 })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIAMonitoring', value: false })
    },
    openContextMenu (event) {
      this.$emit('openContextMenu', event)
    }

  }
}
</script>
