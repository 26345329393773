import { plexiRequest } from '.'

export const getVersion = async () => {
  // return plexiRequest({ url: 'auth/version-info/' })
  // TODO: This is hardcoded. We still need the real call to the back
  return {
    gui: 'v24.3' + '-' + process.env.VUE_APP_VERSION,
    aritatari: 'v24.3',
    tia: 'v24.1'
  }
}
